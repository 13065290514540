import styled from "styled-components";

const gap = 120;

export const Anchor = styled.div`
  display: block;
  height: ${gap}px;
  margin-top: -${gap}px;
  visibility: hidden;
`;

export const Title = styled.header`
  padding-top: 5%;
  font-size: 80px;

  @media only screen and (max-width: 1340px) {
    font-size: 40px;
  }
`;

export const Article = styled.article`
  background: ${(props) => props.background || "rgba(81, 192, 40, 1)"};
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;
