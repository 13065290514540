import Header from "./components/Header.js";
import Main from "./components/Main.js";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {  
     margin: 0;
     text-align: center;
     scroll-behavior: smooth;
     color: white;
}   
   body {
     color: white;
   }
`;

const Application = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap");
  font-family: "Comfortaa", cursive;
`;

export default function App() {
  return (
    <Application>
      <GlobalStyle />
      <Header />
      <Main />
      <footer></footer>
    </Application>
  );
}
