import styled from "styled-components";
import { Anchor, Title, Article } from "./Common.js";
import { useEffect, useState } from "react";

const MainDiv = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 900px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const FormElementDiv = styled.div`
  margin-bottom: 10px;
  font-size: 40px;
  display: flex;
  label {
    text-align: left;
    width: 50%;
  }
  input {
    font-size: 20px;
    width: 50%;
    height: 40px;
    border-radius: 10px;
    align-content: right;
    border: 1px solid white;
    color: gray;
  }

  @media only screen and (max-width: 800px) {
    font-size: 25px;
    input {
      font-size: 18px;
    }
  }
`;

const SubmitDiv = styled.div`
  align-items: center;
`;

const Submit = styled.input`
  font-size: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  width: 400px;
  height: 60px;
  font-size: 50px;
  border-radius: 30px;
  border: 3px solid;
  background: ${(props) =>
    props.disabled ? "grey" : "rgba(81, 192, 40, 0.5)"};
  user-select: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-family: "Comfortaa";

  &:hover {
    transform: translateY(1px);
    background: ${(props) =>
      props.disabled ? "grey" : "rgba(255, 255, 0, 0.5)"};
  }

  @media only screen and (max-width: 1340px) {
    width: 250px;
    font-size: 30px;
  }
`;

const Submitted = styled.h2`
  /*visibility: ${(props) => props.show};*/
  opacity: ${(props) => (props.show ? 1 : 0)};
  font-size: 40px;
  height: 20px;
  color: yellow;
`;

function FormElement(props) {
  return (
    <FormElementDiv>
      <label htmlFor={props.name}>{props.children}</label>
      <input
        id={props.name}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        min={props.min}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
        required
      />
    </FormElementDiv>
  );
}

function Tomorrow() {
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  var dd = tomorrow.getDate();
  var mm = tomorrow.getMonth() + 1;
  var yyyy = tomorrow.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  return `${yyyy}-${mm}-${dd}`;
}

export default function Trial() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [submitted, setSubmitted] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setName("");
    setPhone("");
    setDate("");
    setSubmitted(true);
  }

  useEffect(() => {
    if (submitted) {
      const t = setTimeout(() => setSubmitted(false), 1500);
      return () => clearTimeout(t);
    }
  }, [submitted]);

  return (
    <>
      <Anchor id="trial" />
      <Article background="rgba(81, 192, 40, 0.8)">
        <Title>Пробное занятие</Title>
        <MainDiv>
          <Form onSubmit={handleSubmit}>
            <FormElement
              name="name"
              type="text"
              value={name}
              placeholder="введите имя"
              onChange={(e) => setName(e.target.value)}
              disabled={submitted}
            >
              Ваше имя:
            </FormElement>
            <FormElement
              name="phone"
              type="text"
              value={phone}
              placeholder="введите телефон"
              onChange={(e) => setPhone(e.target.value)}
              disabled={submitted}
            >
              Ваш телефон:
            </FormElement>
            <FormElement
              name="date"
              type="date"
              min={Tomorrow()}
              value={date}
              onChange={(e) => setDate(e.target.value)}
              disabled={submitted}
            >
              Дата занятия:
            </FormElement>
            <Submitted show={submitted}>Заявка отправлена</Submitted>
            <SubmitDiv>
              <Submit type="submit" value="Записаться" disabled={submitted} />
            </SubmitDiv>
          </Form>
        </MainDiv>
      </Article>
    </>
  );
}
