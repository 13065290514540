import Logo from "./Logo.js";
import Menu from "./Menu.js";
import HeaderContacts from "./HeaderContacts.js";
import styled from "styled-components";

const OuterHeaderDiv = styled.header`
  position: fixed;
  top: 0;
  z-index: 10;
  display: block;
  width: 100vw;
  height: 120px;
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  background: rgba(81, 192, 40, 1);
`;

const Swap = styled.div`
  @media only screen and (max-width: 1340px) {
    &:last-child {
      order: -1;
    }
    &:first-child {
      order: -1;
    }
  }
`;

export default function Header() {
  return (
    <OuterHeaderDiv>
      <HeaderDiv>
        <Swap>
          <Logo />
        </Swap>
        <Swap>
          <Menu />
        </Swap>
        <Swap>
          <HeaderContacts />
        </Swap>
      </HeaderDiv>
    </OuterHeaderDiv>
  );
}
