import styled from "styled-components";
import Gift from "../images/gift.svg";
import Handshake from "../images/handshake.svg";
import Riding from "../images/riding.svg";
import Cert from "../images/cert.svg";
import Funhorse from "../images/funhorse.svg";
import Horselove from "../images/horselove.svg";
import { Anchor, Title, Article } from "./Common.js";

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
  max-width: 850px;
`;

const Info = styled.div`
  height: 300px;
  min-width: 250px;
  flex: 1;
  font-size: 25px;
  margin-left: 10px;
  margin-right: 20px;
`;

const InfoImg = styled.img`
  width: 150px;
  height: 110px;
`;

export default function Why() {
  return (
    <>
      <Anchor id="why" />
      <Article background="rgba(81, 192, 40, 0.9)">
        <Title>Почему мы?</Title>
        <MainDiv>
          <Info>
            <InfoImg src={Gift} alt="Первое занятие бесплатно" />
            <p>
              Первое занятие
              <br />
              бесплатно
            </p>
          </Info>
          <Info>
            <InfoImg src={Handshake} alt="Найдем подход к любому" />
            <p>
              Найдем подход <br /> к любому
            </p>
          </Info>
          <Info>
            <InfoImg src={Riding} alt="Найдем подход к любому" />
            <p>
              Конные прогулки <br /> по живописным <br />
              местам
            </p>
          </Info>
          <Info>
            <InfoImg src={Cert} alt="Профессиональные тренеры" />
            <p>
              Профессиональные
              <br /> тренеры
            </p>
          </Info>
          <Info>
            <InfoImg src={Funhorse} alt="Дружелюбные лошади" />
            <p>
              Дружелюбные <br /> лошади
            </p>
          </Info>
          <Info>
            <InfoImg src={Horselove} alt="Дружелюбные лошади" />
            <p>Иппотерапия</p>
          </Info>
        </MainDiv>
      </Article>
    </>
  );
}
