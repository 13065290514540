import About from "./About.js";
import Why from "./Why.js";
import styled from "styled-components";
import Trainers from "./Trainers.js";
import Contacts from "./Contacts.js";
import Trial from "./Trial.js";

const MainDiv = styled.div``;

const Filler = styled.div`
  display: block;
  height: 120px;
  visibility: hidden;
`;

const on = true;

export default function Main() {
  return (
    <>
      <Filler />
      <MainDiv>
        {on && <About />}
        {on && <Why />}
        {on && <Trainers />}
        {on && <Contacts />}
        <Trial />
      </MainDiv>
    </>
  );
}
