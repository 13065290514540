import styled from "styled-components";
import SVG from "../images/menu.svg";
import { useState } from "react";

const Dropdown = styled.nav`
  img {
    display: none;
  }
  @media only screen and (max-width: 1340px) {
    img {
      width: 60px;
      height: 60px;
      cursor: pointer;
      display: block;
      z-index: 2;
      position: relative;
    }
    ul {
      padding-top: 60px;
      display: ${(props) => (props.show ? "flex" : "none")};
      flex-direction: column;
      position: absolute;
      top: 20px;
      right: 0px;
      z-index: 1;
    }
  }

  @media only screen and (max-width: 760px) {
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

const MenuList = styled.ul`
  list-style: none;
  & > li {
    display: inline-block;
    margin-left: 10px;
  }
  a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    background: rgba(81, 192, 40, 0.5);
  }

  a:hover {
    background: rgba(255, 255, 0, 0.5);
  }
  @media only screen and (max-width: 1340px) {
    display: none;
    background: rgba(81, 192, 40, 0.8);
    align-content: right;
    li {
      list-style: none;
      text-align: right;
      margin: 10px;
    }
    a {
      font-size: 25px;
      background: none;
    }
  }
`;

export default function Menu() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Dropdown show={showMenu}>
      <img src={SVG} alt="Меню" onClick={() => setShowMenu((prev) => !prev)} />
      <MenuList>
        <li>
          <a href="#about">O школе</a>
        </li>
        <li>
          <a href="#why">Почему мы</a>
        </li>
        <li>
          <a href="#trainers">О тренерах</a>
        </li>
        <li>
          <a href="#contacts">Контакты</a>
        </li>
        <li>
          <a href="#trial">Пробное занятие</a>
        </li>
      </MenuList>
    </Dropdown>
  );
}
