import styled from "styled-components";
import Vk from "../images/vk.svg";
import Tg from "../images/telegram.svg";

const HContacts = styled.p`
  font-size: 20px;
  font-weight: 700;
  padding-right: 20px;

  @media only screen and (max-width: 760px) {
    font-size: 15px;
  }
`;

const Image = styled.img`
  height: 14px;
  margin-left: 15px;
`;

export default function HeaderContacts() {
  return (
    <HContacts>
      +7 (999) 123 45 67
      <a href="https://vk.com" target="_blank" rel="noreferrer">
        <Image src={Vk} height="12" alt="vk.com" />
      </a>
      <a href="https://telegram.com" target="_blank" rel="noreferrer">
        <Image src={Tg} height="12" alt="telegram.org" />
      </a>
    </HContacts>
  );
}
