import styled from "styled-components";
import { Anchor, Title, Article } from "./Common.js";
import Left from "../images/left.svg";
import Right from "../images/right.svg";
import { useState } from "react";

const bios = [
  {
    image: "https://thispersondoesnotexist.com/image",
    name: "Фамилия Имя Отчество",
    bio:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl nisi scelerisque eu ultrices vitae auctor eu. Nunc sed augue lacus viverra. Leo vel fringilla est ullamcorper eget nulla facilisi. Sed sed risus pretium quam vulputate dignissim suspendisse."
  },
  {
    image: "https://thiscatdoesnotexist.com/",
    name: "Котэ Петров",
    bio:
      "Мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу мяу."
  },
  {
    image: "https://thishorsedoesnotexist.com/",
    name: "Конь Николаич",
    bio:
      "Игого игого игого игого игого игого игого игого игого игого игого игого игого игого игого игого игого."
  }
];

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const Arrow = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;

  @media only screen and (max-width: 760px) {
    width: 60px;
    height: 60px;
  }
`;

const Portrait = styled.img`
  width: 400px;
  height: 400px;
  @media only screen and (max-width: 760px) {
    width: 180px;
    height: 180px;
  }
`;

const TrainerBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  margin-left: 50px;
  margin-right: 50px;
`;

const InfoDiv = styled.div`
  max-width: 400px;
  @media only screen and (max-width: 1100px) {
    margin-top: 20px;
    max-width: 100%;
    min-height: 30px;
  }
`;

const Bio = styled.div`
  margin-top: 10px;
  margin-left: 20px;
  text-align: left;
`;

export default function Trainers() {
  const [coach, setCoach] = useState(0);

  function handleClickLeft() {
    const next = coach > 0 ? coach - 1 : bios.length - 1;
    setCoach((prev) => next);
  }

  function handleClickRight() {
    const next = coach < bios.length - 1 ? coach + 1 : 0;
    setCoach((prev) => next);
  }

  return (
    <>
      <Anchor id="trainers" />
      <Article background="rgba(81, 192, 40, 0.8)">
        <Title>Наши тренеры</Title>
        <MainDiv>
          <Arrow src={Left} alt="назад" onClick={handleClickLeft} />
          <TrainerBlock>
            <Portrait src={bios[coach].image} />
            <InfoDiv>
              <h1>{bios[coach].name}</h1>
              <Bio>{bios[coach].bio}</Bio>
            </InfoDiv>
          </TrainerBlock>
          <Arrow src={Right} alt="вперед" onClick={handleClickRight} />
        </MainDiv>
      </Article>
    </>
  );
}
