import SVG from "../images/logo.svg";
import styled from "styled-components";

const LogoDiv = styled.img`
  height: 100px;
  padding-bottom: 20px;
  padding-left: 20px;
  @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap");
  @media only screen and (max-width: 760px) {
    height: 50px;
  }
`;

export default function Logo() {
  return (
    <a href="#about">
      <LogoDiv src={SVG} alt="Конная школа Анастасия" />
    </a>
  );
}
