import background from "../images/about.png";
import styled from "styled-components";
import { Title, Anchor } from "./Common.js";

const Article = styled.article`
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  padding-bottom: 3%;
`;

const MainDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 5%;
  margin-right: 5%;
  flex-direction: column;
`;

const AboutTitle = styled(Title)`
  text-align: left;
`;

const SubTitle = styled(AboutTitle)`
  margin-top: 1%;
  font-size: 60px;
  @media only screen and (max-width: 760px) {
    font-size: 30px;
  }
`;

const TrialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  width: 400px;
  height: 60px;
  font-size: 50px;
  border-radius: 30px;
  border: 3px solid;
  background: rgba(81, 192, 40, 0.5);
  user-select: none;
  cursor: pointer;

  a:link {
    text-decoration: none;
  }

  &:hover {
    transform: translateY(1px);
    background: rgba(255, 255, 0, 0.5);
  }

  @media only screen and (max-width: 1340px) {
    width: 250px;
    font-size: 30px;
  }
`;

export default function About() {
  return (
    <>
      <Anchor id="about" />
      <Article>
        <MainDiv>
          <AboutTitle>Школа верховой езды "Анастасия"</AboutTitle>
          <SubTitle>Для любого возраста</SubTitle>
          <TrialButton>
            <a href="#trial">Записаться</a>
          </TrialButton>
        </MainDiv>
      </Article>
    </>
  );
}
