import styled from "styled-components";
import { Anchor, Title, Article } from "./Common.js";
import mapboxgl from "mapbox-gl";
import Map, { Marker } from "react-map-gl";
import Pin from "../images/pin.svg";
import Phone from "../images/phone.svg";
import Email from "../images/email.svg";
import PinWhite from "../images/pin-white.svg";
import Vk from "../images/vk.svg";
import Tg from "../images/telegram.svg";

const coords = [55.873557, 37.303402];

const MainDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const MapBlock = styled.div`
  height: 300px;
  min-width: 400px;
  margin: 20px;
  flex: 1;
  @media only screen and (max-width: 500px) {
    min-width: 300px;
    height: 250px;
  }
`;

const ContactsDiv = styled.div`
  margin: 20px;
  font-size: 25px;
  min-width: 400px;
  flex: 1;

  address {
    font-style: normal;
  }

  a:link {
    text-decoration: none;
  }

  @media only screen and (max-width: 600px) {
    font-size: 15px;
    min-width: auto;
  }
`;

const ContactsList = styled.ul`
  list-style: none;
  line-height: 2;
  padding-left: 0;
  li {
    text-align: left;
  }
`;

const Image = styled.img`
  width: 28px;
  @media only screen and (max-width: 600px) {
    width: 20px;
  }
`;

export default function Contacts() {
  mapboxgl.accessToken =
    "pk.eyJ1Ijoid3JremciLCJhIjoiY2w1MTRsMW41MDI0ejNkcnliczMyNmpjNyJ9.Oydx12NzpiCwXiQ8qKG9-Q";
  return (
    <>
      <Anchor id="contacts" />
      <Article background="rgba(81, 192, 40, 0.9)">
        <Title>Контакты</Title>
        <MainDiv>
          <ContactsDiv>
            <address>
              <ContactsList>
                <li>
                  <Image src={Phone} alt="phone" /> +7 (999) 123 45 67{" "}
                </li>
                <li>
                  <a href="mailto:anastasia@otrada.ru">
                    <Image src={Email} alt="email" /> anastasia@otrada.ru
                  </a>
                </li>
                <li>
                  <Image src={PinWhite} alt="address" /> Клубная ул., 3, корп.
                  2, посёлок Отрадное
                </li>
                <li>
                  <a href="https://vk.com">
                    <Image src={Vk} alt="vk" /> Сообщество ВКонтакте
                  </a>
                </li>
                <li>
                  <a href="https://telegram.org">
                    <Image src={Tg} alt="telegram" /> Наш канал в Telegram
                  </a>
                </li>
              </ContactsList>
            </address>
          </ContactsDiv>
          <MapBlock>
            <Map
              initialViewState={{
                longitude: coords[1],
                latitude: coords[0],
                zoom: 10
              }}
              mapStyle="mapbox://styles/mapbox/light-v10"
            >
              <Marker
                longitude={coords[1]}
                latitude={coords[0]}
                anchor="center"
              >
                <img src={Pin} alt="pin" width="40" />
              </Marker>
            </Map>
          </MapBlock>
        </MainDiv>
      </Article>
    </>
  );
}
